<template>
  <v-container>
    <div class="d-flex mb-4">
      <h2>Manage Promotions</h2>
      <v-spacer></v-spacer>
      <v-btn color="success" class="mr-4" @click.native="createPromotion()">
        New Promotion
      </v-btn>
    </div>
    <v-data-table @click:row="editPromotion" :items="promotions" :headers="headers" :disable-pagination="true" :hide-default-footer=true dense>
      <template v-slot:[`item.managePromotion`]="{ item }">
        <v-btn small color="success" class="mr-4" @click.stop="emailPromotion(item)">
          Email
        </v-btn>
      </template>
    </v-data-table>
    <promotion-dialog v-if="openDialog" v-model='openDialog' :data="currentPromotion" @close="closeDialog" />
    <email-promotion-dialog v-if="openEmailDialog" v-model='openEmailDialog' :promotion="currentPromotion" @close="closeEmailDialog" />
  </v-container>
</template>

<script>
import PromotionApi from '@/api/promotion'
import PromotionDialog from './components/ProductPromotionDialog'
import EmailPromotionDialog from './components/EmailProductPromotionDialog'

export default {
  components: {
    PromotionDialog,
    EmailPromotionDialog
  },
  data() {
    return {
      openDialog: false,
      openEmailDialog: false,
      promotions: [],
      currentPromotion: null,
      headers: [
        { text: 'Title', value: 'title' },
        { text: 'Code', value: 'artistCode', width: 100 },
        { text: 'Type', value: 'type' },
        { text: '', value: 'managePromotion'}
      ],
    }
  },
  async created() {
    this.loadPromotions()
  },
  mounted: function () {},
  computed: {},
  watch: {},
  methods: {
    loadPromotions() {
      PromotionApi.loadPromotions().then((results) => {
      this.promotions = results.data
      }).catch(
        err => {
          console.log('err', err)
          this.loadingData = false
        }
      )
    },
    createPromotion() {
      this.currentPromotion = null
      this.openDialog = true
    },
    emailPromotion(evt) {
      this.currentPromotion = evt
      this.openEmailDialog = true
    },
    editPromotion(evt) {
      this.currentPromotion = evt
      this.openDialog = true
    },
    closeDialog(element) {
      this.loadPromotions()
      this.openDialog = false
    },
    closeEmailDialog(element) {
      this.loadPromotions()
      this.openEmailDialog = false
    }
  }
}
</script>
